*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    /* font-family: 'SF Pro Display', 'Arial', sans-serif; */
}
  
[contenteditable] {
    -webkit-user-select: text!important;
    user-select: text!important;
}
  
.day{
    --gradient-color: rgba(255, 255, 255, 0.5);
    --background-global: #EFEFF5;
    --background-island: #FFFFFF;
    --background-substrate-default: #FFFFFF;
    --background-substrate-disabled-gray: #E3E3EA;
    --background-substrate-active: #037EE5;
    --background-substrate-input: #F2F8FD;
    --background-substrate-active-blue: #F2F8FD;
    --background-label-green: #EBFAEF;
    --background-label-gray: #F3F7FF;
    --background-label-red: #FFF5F3;
  
    --title-primary: #000000;
    --subhead-primary: #3C3C43;
    --subhead-secondary: #868686;
    --text-tag-medium-gray: #909090;
  
    --controlaccent-white: #FFFFFF;
    --controlaccent-blue: #037EE5;
    --controlaccent-red: #FE4949;
    --controlaccent-orange: #FE7449;
    --controlaccent-green: #34C759;
    --controlaccent-yellow: #F1BE12;
  
    --button-blue-default: #037EE5;
    --button-green-active: #34C759;
    --button-gray-disabled: #E3E3EA;
  
    --modal-behind-gray: #A6A6A6;
    --modal-attention-and: #FFFFFF;
    --modal-behind-ios: rgba(242, 242, 242, 0.8);
  
    --system-primary: #FFFFFF;
    --system-background: #000000;
    --statusbar-gray-text: #7F7F86;
    --system-blue-active: #007AFF;
    --system-gray-default: #8D8D8F;
    --system-scroll: #C6C6C6;
    --system-gray-icons: #909090;
    --system-home-indicator: #000000;
    --system-input: #E3E3EA;
}
  
.night{
    --gradient-color: rgba(44, 44, 46, 0.5);
    --background-global: #1C1C1E;
    --background-island: #2c2c2e;
    --background-substrate-default: #243042;
    --background-substrate-disabled-gray: #474747;
    --background-substrate-active: #037EE5;
    --background-substrate-input: #1C1C1E;
    --background-substrate-active-blue: #243042;
    --background-label-green: #2D3D30;
    --background-label-gray: #1F2029;
    --background-label-red: #442F2F;
  
    --title-primary: #FFFFFF;
    --subhead-primary: #FFFFFF;
    --subhead-secondary: #999999;
    --text-tag-medium-gray: #868686;
  
    --controlaccent-white: #FFFFFF;
    --controlaccent-blue: #037EE5;
    --controlaccent-red: #FE4949;
    --controlaccent-orange: #FE7449;
    --controlaccent-green: #34C759;
    --controlaccent-yellow: #F1BE12;
  
    --button-blue-default: #037EE5;
    --button-green-active: #34C759;
    --button-gray-disabled: #2C2C2E;
  
    --modal-behind-gray: #131117;
    --modal-attention-android: #2C2C2E;
    --modal-behind-ios: rgba(30, 30, 30, 0.75);
  
    --system-primary: #FFFFFF;
    --system-background: #000000;
    --statusbar-gray-text: #999999;
    --system-blue-active: #007AFF;
    --system-gray-default: #8D8D8F;
    --system-scroll: #1F1F21;
    --system-gray-icons: #8D8D8F;
    --system-home-indicator: #FFFFFF;
    --system-input: #070707;
  
}
  
.text{
    color: var(--title-primary);
    /* font-family: arial, sans-serif, */
}
  
.android__fonts {
    font-family: 'Roboto', 'Arial', sans-serif !important;
}
  
.laptop__fonts {
    font-family: 'Arial', sans-serif !important;
}
  
.iphone__fonts{
    font-family: 'SF Pro Display', 'Arial', sans-serif !important;
}
  
.root__container{
    /* width: 100vw;
    height: 100vh;
    overflow: scroll; */
    min-height: 100vh;
    padding: 15px;
    background-color: var(--background-global);
}
  
.top__block{
    width: 100%;
    height: 500px!important;
}
  
  /* .blockScroll{
    overflow: hidden!important;
  } */
  
.hidden__input {
    width: 0;
    height: 0;
    border: 0;
    -webkit-appearance: none;
    background-color: transparent;
}
  
.bones{
    animation-duration: 1.5s;
    animation-name: shining;
    animation-iteration-count: infinite;
    border-radius: 5px!important;
    animation-timing-function: linear;
    /* background-color: var(--tg-theme-secondary-bg-color)!important; */
}
  
.bones__reversed{
    animation-duration: 1.5s;
    animation-name: shiningReversed;
    animation-iteration-count: infinite;
    border-radius: 5px!important;
    animation-timing-function: linear;
    /* background-color: var(--tg-theme-secondary-bg-color)!important; */
}
  
[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}
  
@keyframes shining {
    0%     {background-color: var(--background-substrate-disabled-gray);border: 1px solid  var(--background-substrate-disabled-gray);}
    50%  {background-color: var(--background-global);border: 1px solid  var(--background-global);}
    100%  {background-color: var(--background-substrate-disabled-gray);border: 1px solid  var(--background-substrate-disabled-gray);}
}
  
@keyframes shiningReversed {
    0%     {background-color: var(--background-global);border: 1px solid  var(--background-global);}
    50%  {background-color: var(--background-substrate-disabled-gray);border: 1px solid  var(--background-substrate-disabled-gray);}
    100.0%  {background-color: var(--background-global);border: 1px solid  var(--background-global);}
}
  
  