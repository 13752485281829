.policy{
    height: calc(100vh - 30px);
    overflow: scroll;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px;
}
.policy__title{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: var(--title-primary);
    margin-bottom: 16px;
}

.policy__content{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--subhead-primary);
}