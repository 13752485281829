.bottom__notify{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 31, 72, 0.5);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    transition: all .25s .25s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    overflow: hidden;
    z-index: 10000;
}

.bottom__notify__modal{
    position: absolute;
    width: 100%;
    background-color: var(--background-island);
    border-radius: 12px 12px 0px 0px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    bottom: -500px;
    transition: all .25s;
    height: fit-content;
    max-height: 90vh;
}

.bottom__notify__modal.huge{
    height: auto;
}

.bottom__notify.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 10000;
    transition: opacity .25s;
}

.bottom__notify.active .bottom__notify__modal{
    bottom: 0;
    transition: all .25s .25s;
}

.bottom__notify__close{
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
}

.bottom__notify__emoji{
    width: 130px;
    height: 130px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.bottom__notify__title{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
    color: var(--title-primary);
}

.bottom__notify__text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--subhead-primary);
    margin-bottom: 32px;
    max-width: 320px;
}

.bottom__notify__buttons{
    width: 100%;
}

.bottom__notify__button{
    width: 100%;
    height: 54px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--background-global);
    color: var(--title-primary);
    border-radius: 12px;
}

.bottom__notify__button:not(:last-child){
    margin-bottom: 8px;
}

.bottom__notify__button.blue{
    background-color: var(--button-blue-default);
    color: var(--controlaccent-white);
}

.bottom__notify__button.disabled{
    background-color: var(--background-substrate-disabled-gray);
    pointer-events: none;
    color: var(--text-tag-medium-gray);
}

.bottom__notify__button__title{
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
}

.bottom__notify__input{
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: var(--background-substrate-input);
    border-radius: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    overflow: scroll;
    min-height: 110px;
    max-height: calc(100vh - 465px);
    letter-spacing: -0.24px;
    color: var(--subhead-primary);
}

.bottom__notify__input__wrapper {
    width: 100%;
    min-height: 110px;
    position: relative;
    margin-bottom: 16px;
    max-height: calc(100vh - 400px);
}

.bottom__notify__input__wrapper span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--subhead-secondary);
    position: absolute;
    left: 16px;
    top: 16px;
}