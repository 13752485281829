.calendarList___scroll{
    height: 110px;
    overflow: hidden;
    width: 56px;
}

.calendarList__container{
    display: flex;
    overflow: scroll;
    flex-direction: column;
    width: 66px;
    padding-right: 10px;
    height: 110px;
    position: relative;
    margin-right: -10px;
}

.calendarList__element{
    width: 56px;
    overflow: hidden;
    color: var(--title-primary);
    margin-top: 2px;
    margin-bottom: 2px;
    padding-bottom: 46px;
    height: 20px;
    text-align: right;
    font-size: 46px;
}