.detail__wrapper{
    background-color: var(--background-island);
    border-radius: 12px;
    padding: 20px 20px 12px;
    position: relative;
}

.detail__textarea{
    border-radius: 5px;
    min-height: 100px;
    width: 100%;
    margin-bottom: 16px;
    color: var(--subhead-primary);
}

.detail__textarea div,
.detail__textarea span,
.detail__textarea ul,
.detail__textarea li,
.detail__textarea li::marker,
.detail__textarea p {
    font-size: 20px!important;
    color: var(--subhead-primary)!important;
    background-color: transparent!important;
}

.detail__textarea.small__text div,
.detail__textarea.small__text span,
.detail__textarea.small__text ul,
.detail__textarea.small__text li,
.detail__textarea.small__text li::marker,
.detail__textarea.small__text p {
    font-size: 16px!important;
}


.detail__textarea:focus{
    outline: none;
}

.detail__chip__wrapper{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.red_icon svg{
    color: var(--controlaccent-red) !important;
    fill: var(--controlaccent-red) !important;
}

.content__filter.detail__filter{
    margin-bottom: 8px;
    background-color: var(--background-substrate-active-blue);
}

.content__filter.detail__filter.active{
    background-color: var(--background-substrate-active);
}

.content__filter.detail__filter.disabled {
    pointer-events: none;
    background-color: var(--background-substrate-disabled-gray);
}

.content__filter.detail__filter.disabled .content__filter__text{
    color: var(--text-tag-medium-gray);
}

.content__filter.detail__filter.disabled svg{
    color: var(--text-tag-medium-gray);
    fill: var(--text-tag-medium-gray);
}

.content__filter.detail__filter.disabled .content__filter__icon{
    color: var(--text-tag-medium-gray);
}

.header__title.bones {
    width: 100%;
    height: 28px;
}

.detail__wrapper.bones {
    width: 100%;
    height: 220px;
}