.lk path {
    stroke: var(--controlaccent-white);
}

.lk__header{
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    background-color: var(--background-island);
    padding: 16px;
    border-radius: 24px;
}

.lk__avatar{
    width: 56px;
    height: 56px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-right: 16px;
    border-radius: 50%!important;
    background-color: var(--controlaccent-blue);
    display: flex;
    align-items: center;
    align-content: center;
    border: 1.06667px solid var(--controlaccent-blue);
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: var(--controlaccent-white);
}

.lk__avatar.grey{
    background-color: var(--modal-behind-gray);
    opacity: 0.4;
}

.lk__avatar__input{
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
}

.lk__avatar__update{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    cursor: pointer;
    position: absolute;
    top: 38px;
    left: 38px;
    background-color: var(--background-substrate-default);
    border-radius: 50%;
}
.lk__avatar__update path{
    stroke: var(--controlaccent-blue);
}

.lk__name{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--title-primary);
    margin-bottom: 4px;
}

.lk__you{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.24px;
    color: var(--subhead-secondary);
}

.lk__block{
    padding: 16px;
    border-radius: 14px;
    background-color: var(--controlaccent-blue);
}
.lk__block.bones{
    height: 222px;
}

.lk__block.lk__notification.bones{
    height: 35px;
}

.lk__block:not(:last-child) {
    margin-bottom: 12px;
}

.lk__block__title{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: var(--controlaccent-white);
    margin-bottom: 8px;
}

.lk__block__icon{
    width: 28px;
    height: 28px;
    background: rgba(243, 247, 255, 0.12);
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.lk__block__describtion{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: center;
    color: var(--controlaccent-white);
    margin-bottom: 24px;
}

.lk__notification{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    cursor: pointer;
    background-color: var(--background-island);
}

.lk__notification .lk__block__icon{
    margin-left: 0;
    margin-right: 12px;
    margin-bottom: 0;
    background-color: var(--background-substrate-active-blue);
}

.lk__notification path{
    stroke: var(--controlaccent-blue);
}

.lk__notification .lk__block__describtion{
    margin-bottom: 0;
    color: var(--title-primary);
}

.lk__notification .content__task__arrow{
    align-self: flex;
    justify-self: flex-end;
    margin-left: auto;
}

.lk__notification .content__task__arrow path{
    stroke: none;
}

.lk__block.yellow{
    background-color: var(--background-island);
}

.lk__block.yellow .lk__block__icon{
    background-color: rgba(241, 190, 18, 0.1);;
}

.lk__block.yellow .lk__block__icon path{
    stroke: var(--controlaccent-yellow);
}

.lk__block.yellow .lk__block__title{
    color: var(--title-primary);
}

.lk__block.yellow .lk__block__describtion{
    color: var(--title-primary);
}

.lk__subscribed{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.lk__subscribed__element {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--title-primary);
}

.lk__subscribed:first-child{
    margin-bottom: 16px;
    margin-top: 20px;
}