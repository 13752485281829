.timezone{
    padding-bottom: 50px;
}

.timeezone__wrapper{
    border-radius: 14px;
    overflow: hidden;
    background-color: var(--background-island);
    padding-left: 14px;
    padding-right: 14px;
}

.timeezone__wrapper:not(:last-child) {
    margin-bottom: 8px;
}

.timezone__card{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%; 
    color: var(--title-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    position: relative;
    justify-content: space-between;
    cursor: pointer;
}

.timezone__card:not(:last-child) {
    border-bottom: 2px solid var(--background-substrate-disabled-gray);
}

.timezone__card span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
}

.timezone__card path{
    stroke: var(--controlaccent-blue);
}

.timezone__card.selected{
    color: var(--background-substrate-active);
}