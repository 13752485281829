.notifications__title{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: var(--title-primary);
    margin-bottom: 16px;
}

.notifications__element{
    width: 100%;
    height: 72px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--controlaccent-white);
    display: flex;
    align-items: center;
    border-radius: 14px;
    align-content: center;
    justify-content: space-between;
}

.notifications__element:not(:last-child){
    margin-bottom: 8px;
}

.notifications__text{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: var(--title-primary);
}

.notifications__label{
    width: 51px;
    height: 31px;
    flex-shrink: 0;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
}

.notifications__input{
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    visibility: hidden;
}

.notification__switcher{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: var(--background-substrate-disabled-gray);
    position: relative;
    transition: all .25s;
}

.notification__switcher:after{
    content: '';
    width: 27px;
    height: 27px;
    background-color: var(--controlaccent-white);
    border-radius: 50%;
    left: 2px;
    top: 2px;
    position: absolute;
    display: block;
    transition: all .25s;
}

.notifications__input:checked ~ .notification__switcher{
    background-color: var(--controlaccent-blue);
}

.notifications__input:checked ~ .notification__switcher:after{
    left: 22px;
}


