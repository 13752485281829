.detail__wrapper{
    background-color: var(--background-island);
    border-radius: 12px;
    padding: 20px 20px 12px;
    position: relative;
}

.content.small__padding{
    padding-bottom: 0;
}

.content__tasklist__title.margin{
    margin-top: 16px;
}

.content__tasklist__title.absolute{
    position: absolute;
}

.content__tasklist__title.middle{
    font-size: 22px;
}

.header__title.middle{
    font-size: 22px;
}

.detail__textarea.small__margin{
    margin-bottom: 16px;
}

.material__elemenet{
    width: 100%;
    padding: 16px;
    display: block;
    background-color: var(--background-island);
    border-radius: 14px;
    margin-top: 8px;
    text-decoration: none;
    position: relative;
}

.material__chip{
    padding: 3px 8px;
    width: fit-content;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.material__chip.video{
    background-color: var(--background-label-green);
    color: var(--controlaccent-green);
}

.material__chip.article{
    background-color: var(--background-substrate-active-blue);
    color: var(--controlaccent-blue);
}

.material__chip.guide{
    background-color: var(--background-label-red);
    color: var(--controlaccent-red);
}

.material__title{
    color: var(--title-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
}

.material__link{
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    outline: none;
    line-height: 16px;
    color: var(--controlaccent-blue);
    margin-top: 8px;
}

.material__link svg{
    color: var(--controlaccent-blue);
    margin-right: 4px;
}

.material__link span{
    display: flex;
    align-items: center;
    align-content: center;
}

.material__picture{
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.material__input{
    width: 100%;
    min-height: 52px;
    padding: 16px;
    font-weight: 400;
    font-size: 16px;
    color: var(--subhead-primary);
    line-height: 20px;
    border: 0;
    border-radius: 11px;
    background-color: var(--background-substrate-input);
    letter-spacing: -0.24px;
    margin-top: 10px;
    position: relative;
}

.material__input__container{
    position: relative;
}

.material__input__container span{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--subhead-secondary);
    pointer-events: none;
    position: absolute;
    left: 16px;
    bottom: 15px;
    display: block;
}


.material__input.disabled {
    color: var(--subhead-secondary);
    pointer-events: none;
}

.material__answer{
    font-style: normal;
    font-weight: 400;
    padding: 16px;
    padding-top: 0;
    font-size: 14px;
    line-height: 16px;
    color: var(--subhead-primary);
    margin-top: 20px;
}

.material__input:focus{
    outline: none;
}

.material__input.neutral{
    color: var(--subhead-primary);
    background-color: var(--background-label-gray);
}

.material__input.green{
    color: var(--subhead-primary);
    background-color: var(--background-label-green);
}

.material__input.red{
    color: var(--subhead-primary);
    background-color: var(--background-label-red);
}

.material__result{
    width: 100%;
    padding: 68px 16px 16px;
    border-radius: 14px;
    background-color: var(--background-island);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 87px;
}


.reset{
    width: 20px;
    height: 20px;
    color: black;
}

.material__lottie{
    width: 150px;
    height: 150px;
    position: absolute;
    top: -80px;
    border-radius: 50%;
}

.material__points{
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    color: var(--controlaccent-green);
    margin-bottom: 16px;
}

.material__points.orange{
    color: var(--controlaccent-orange);
}

.material__describtion{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: var(--title-primary);
}

.material__result__marks{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 16px;
}

.material__button{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 1px solid var(--background-substrate-active-blue);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    transition: all .2s;
    background-color: var(--background-island);
}

.material__like{
    transform: rotate(180deg) scale(-1, 1);
}

.material__button:hover{
    background-color: var(--background-substrate-active-blue);
}

.material__button:focus{
    background-color: var(--background-substrate-active-blue);
}

.material__button:first-child{
    margin-right: 24px;
}

.material__button.active{
    background-color: var(--background-substrate-active-blue);
}
.content__filter.outdated .content__filter__text{
    color: var(--controlaccent-red)!important;
}

.content__filter.outdated{
    background-color: var(--background-label-red)!important;
    border: 1px solid var(--controlaccent-red)!important;
}

.content__filter.detail__filter.outdated .content__filter__icon{
    color: var(--controlaccent-red)!important;
}

.content__filter.detail__filter.outdated svg{
    color: var(--controlaccent-red)!important;
    fill: var(--controlaccent-red)!important;
}