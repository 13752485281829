.subscribe{
    position: relative;
    padding-top: 95px;
}

.subscribe__bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;
    background: radial-gradient(42.07% 71.33% at 50% 71.33%, #FFB800 0%, rgba(255, 168, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    filter: blur(30px);
    z-index: 10;
}

.subscribe__content{
    position: relative;
    z-index: 11;
    margin-bottom: 36px;
}

.subscribe__block{
    padding: 16px;
    background-color: var(--background-island);
    border-radius: 14px;
}

.subscribe__block.flex{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.subscribe__block__describe{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: -0.24px;

    color: var(--title-primary);
    margin-right: 15px;
}

.subscribe__block__value{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: var(--controlaccent-yellow);
    min-width: max-content;
    flex-shrink: 0;
}

.subscribe__block__value .grey_span{
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.24px;
    color: var(--subhead-secondary);
}

.subscribe__block:not(:last-child) {
    margin-bottom: 8px;
}

.subscribe__block__lottie{
    width: 120px;
    height: 120px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: -82px;
    margin-bottom: 10px;
}

.subscribe__block__title{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
    color: var(--title-primary);
}

.subscribe__block__text{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: var(--title-primary);
}

.subscribe__block__subtitle{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--title-primary);
    margin-bottom: 8px;
}

.subscribe__block__price{
    color: var(--subhead-secondary);
    font-size: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.subscribe__block__price span{
    font-weight: 600;
    font-size: 22px;
    color: var(--controlaccent-yellow);
}

.subscribe__subtitle{
    margin-top: 16px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--title-primary);
}

.subscribe__benifits__element{
    display: flex;
    align-items: center;
    position: relative;
    align-content: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--title-primary);
}

.subscribe__icon{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 12px;
    width: 28px;
    border-radius: 5px;
    flex-shrink: 0;
    height: 28px;
    background-color: var(--background-substrate-active-blue);
}

.subscribe__icon path{
    stroke: var(--controlaccent-blue);
}

.subscribe__agree{
    margin-top: 16px;
    display: flex;
    align-items: center;
    align-content: center;
}

.subscribe__agree__label{
    position: relative;
    margin-right: 14px;
    flex-shrink: 0;
}

.subscribe__checkkbox__input{
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
}

.subscribe__checkbox{
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--background-substrate-default);
    border-radius: 5px;
}

.subscribe__checkbox path{
    stroke: var(--controlaccent-blue);
}

.subscribe__agree__text{
    font-size: 16px;
    line-height: 20px;
    color: var(--subhead-primary);
}

.subscribe__checkkbox__input:checked ~ .subscribe__checkbox{
    background-color: var(--background-substrate-active-blue);
}

.subscribe__agree__text span{
    color: var(--controlaccent-blue);
    cursor: pointer;
}

.subscribe__benifits__arrow{
    position: absolute;
    right: 16px;
    top: calc(50% - 7px);
    height: 14px;
    width: 8px;
}

.subscribe__autopay{
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--subhead-secondary);
}

.subscribe__autopay span{
    color: var(--controlaccent-blue);
    cursor: pointer;
}
