.main_page{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 36px);
}

.header{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
}

.header__title{
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    font-family: 'SF Pro Display', 'Arial', sans-serif;
}

.header__filters{
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
}

.content__experts__wrapper{
    position: relative;
}

.content__experts__list{
    position: relative;
    margin-top: 0;
    transition: all .3s;
}

.content__experts__list.active{
    margin-top: 36px;
}

.task__outdated__container{
    margin-bottom: 8px;
}

.header__icon{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--background-substrate-default);
    border-radius: 50px;
    cursor: pointer;
    font-weight: bold;
    font-size: 22px;
    color: var(--title-primary);
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.header__icon svg{
    fill: var(--controlaccent-blue);
    color: var(--controlaccent-blue);
    transition: all .2s;
}

.header__icon.active{
    background-color: var(--background-substrate-active);
}

.header__icon.active svg{
    fill: var(--controlaccent-white);
    color: var(--controlaccent-white);
}

.icon__avatar.active{
    background-color: var(--background-island);
    border: 2px solid var(--controlaccent-blue);
}

.content{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    padding-bottom: 60px;
}

.content__filters__overflow{
    overflow: hidden;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 0;
}

.content__filters{
    padding-bottom: 20px;
    margin-bottom: -20px;
    display: flex;
    overflow-y: scroll;
    width: 100%;
    overflow: scroll;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.content__filter{
    padding: 9px 13px 11px;
    border-radius: 12px;
    background-color: var(--background-substrate-default);
    display: flex;
    min-height: 40px;
    min-width: max-content;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-right: 10px;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.content__filter__text{
    color: var(--controlaccent-blue);
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    transition: all .2s;
}

.content__filter__icon{
    display: flex;
    align-items: center;
    align-content: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
}

.content__filter__icon:first-child{
    margin-right: 6px;
}

.content__filter__icon:last-child{
    margin-left: 6px;
}
.content__filter.solo_icon{
    width: 40px;
    height: 40px;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.content__filter__icon.solo_icon{
    margin-right: 0;
    width: 15px;
    height: 15px;
}

.content__noTasks{
    flex: 1 1;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-style: italic;
    flex-direction: column;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: var(--tg-theme-hint-color);
}


.content__filter svg {
    width: 90%;
    height: 90%;
    transition: all .2s;
}

.content__filter:not(.special__styles) svg  {
    fill: var(--controlaccent-blue);
    color: var(--controlaccent-blue);
    width: 100%;
    height: 100%;
}

.content__filter.special__styles path, .content__filter.special__styles rect{
    stroke: var(--controlaccent-blue);
}

.content__filter.active{
    background-color: var(--background-substrate-active);
}

.content__filter.active .content__filter__text{
    color: var(--controlaccent-white);
}

.content__filter.active:not(.special__styles) svg{
    fill: var(--controlaccent-white);
    color: var(--controlaccent-white);
}

.content__filter.active.special__styles path, .content__filter.active.special__styles rect{
    stroke: var(--controlaccent-white);
}

.content__tasklist__title{
    font-style: normal;
    font-weight: 600;
    color: var(--title-primary);
    font-size: 18px;
    line-height: 28px;
}

.content__tasklist__title.margin__bottom{
    margin-bottom: 8px;
}

.content__tasklist__title.bones__reversed{
    height: 28px;
    width: 110px;
}

.content__tasklist{
    margin-bottom: 20px;
    margin-top: 8px;
}

.colorTest{
    width: 200px;
    height: 50px;
    border: 1px solid black;
}

.create_task{
    position: fixed;
    bottom: auto;
    transition: top .27s .07s;
    right: 18px;
    top: calc(var(--tg-viewport-stable-height) - 75px);
    left: auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: var(--controlaccent-white);
    background-color: var(--button-blue-default);
    border-radius: 40px;
    width: 60px;
    height: 60px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    z-index: 20;
}

.create_task:after{
    content: '';
    height: 21px;
    width: 0;
    border: 1px solid var(--controlaccent-white);
    display: block;
    position: absolute;
}

.create_task:before{
    content: '';
    width: 21px;
    height: 0;
    border: 1px solid var(--controlaccent-white);
    display: block;
    position: absolute;
}

.content__noTasks__lottie{
    width: 160px;
    height: 160px;
}

.content__noTasks__text{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: var(--subhead-secondary);
    text-align: center;
    letter-spacing: -0.08px;
    margin-top: 32px;
    max-width: 315px;
}

.content__tasklist__bg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--background-substrate-disabled-gray);
    z-index: 3;
    opacity: 1;
    transition: all .4s;
}

.content__tasklist__hide{
    width: calc(100% - 47px);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-left: 22px;
    margin-top: -45px;
    border-radius: 14px;
    z-index: 0;
    overflow: hidden;
    margin-bottom: 20px;
    transition: margin-top .6s, max-height .6s, width .3s .3s, margin-left .3s .3s;
}

.content__tasklist__hide.active{
    max-height: 800px;
    margin-top: 0;
    width: 100%;
    margin-left: 0;
    transition: margin-top .6s, max-height .6s, width .01s, margin-left .01s;
}

.content__tasklist__hide.active .content__tasklist__bg{
    opacity: 0;
    pointer-events: none;
}

.content__tasklist__collapse{
    display: flex;
    align-items: center;
    align-content: center;
    width: fit-content;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--title-primary);
    margin-left: auto;
    margin-right: auto;
    background-color: var(--background-island);
    border-radius: 20px;
}

.content__tasklist__collapse svg{
    transform: rotate(-90deg);
    margin-left: 6px;
    width: 5px;
    height: 7px;
    fill: var(--title-primary);
    color: var(--title-primary);
}

.content__tasklist__collapse path{
    fill: var(--title-primary);
    color: var(--title-primary);
    fill-opacity: 1;
}


.header__tabs{
    display: flex;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--background-substrate-disabled-gray);
    border-radius: 100px;
    padding: 10px 14px;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
}

.header__tabs:before{
    content: '';
    position: absolute;
    width: 110px;
    left: 4px;
    height: 32px;
    border-radius: 50px;
    top: 4px;
    background-color: var(--system-blue-active);
    transition: all .2s;
}

.header__tabs.experts:before{
    width: 112px;
    left: 124px;
}

.header__tab{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-tag-medium-gray);
    transition: all .2s;
    position: relative;
}

.header__tab.active{
    color: var(--controlaccent-white);
}

.expert__wrapper{
    width: 100%;
}

.expert__wrapper:not(:last-child) {
    margin-bottom: 16px;
}

.expert__header{
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 13px 13px 2px 2px;
    margin-bottom: 1px;
    background-color: var(--background-island);
}

.expert__header:not(:first-child) {
    margin-top: 16px;
}

.create_task.prevent_plus:after, .create_task.prevent_plus::before {
    content: none;
}

.create_task.prevent_plus{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.expert__avatar{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    margin-right: 16px;
    background-color: var(--controlaccent-blue);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: var(--controlaccent-white);
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.expert__name{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--subhead-primary);
    margin-bottom: 4px;
}

.expert__description{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--subhead-secondary);
}

@media(max-width: 370px) {
    .header__tabs {
        width: 200px;
        padding: 6px 10px;
        height: 34px;
    }

    .header__tab{
        font-size: 14px;
    }

    .header__tabs:before{
        height: 28px;
        width: 92px;
        top: 3px;
    }

    .header__tabs.experts:before{
        width: 94px;
        left: 103px;
    }

    .header__icon{
        width: 34px;
        height: 34px;
        font-size: 14px;
    }
}