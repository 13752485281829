.content__task{
    width: calc(100vw - 30px);
    height: fit-content;
    margin-left: 15px;
    padding: 16px;
    padding-right: 20px;
    flex-shrink: 0;
    background-color: var(--background-island);
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    border-radius: 13px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.content__task__hidden:not(:first-child){
    margin-top: 8px;
}

.content__task__hidden{
    width: 100%;
    padding-right: 0;
    border-radius: 0 8px 8px 0;
    overflow-y: hidden;
    opacity: 1;
}

.content__task__container.overflow_hidden{
    overflow: hidden;
}

.content__task__hidden.deleted{
    transition: opacity .3s .2s;
    opacity: 0;
}

.content__task__avatar{
    width: 28px;
    height: 28px;
    max-width: 28px;
    min-width: 28px;
    margin-right: 12px;
    border-radius: 5px;
    background-color: var(--background-global);
    color: var(--title-primary);
    font-size: 17px;
    display: flex;
    font-weight: bold;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.content__task__content{
    height: 100%;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content__task__header{
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.content__task__header.bones{
    height: 19px;
    border-radius: 5px;
}

.content__task__critical{
    background-color: var(--background-label-gray);
    border-radius: 10px;
    color: var(--text-tag-medium-gray);
    height: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    display: flex;
    align-items: center;
    padding: 2px 6px 2px;
    align-content: center;
    font-size: 11px;
}

.content__task__critical svg{
    fill: var(--controlaccent-red);
    color: var(--controlaccent-red);
    padding-right: 4px;
}

.content__task__middle {
    max-height: 40px;
    margin-bottom: 8px;
}

.content__task__middle.bones{
    border-radius: 5px;
}

.content__task__text{
    width: 100%;
    color: var(--title-primary);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    word-break: break-all;
    line-height: 20px;
    margin-right: 15px;
    height: 100%;
}

.content__task__author{
    width: 100%;
    color: var(--title-primary);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 15px;
    height: 100%;
}

.content__task__text__macos{
    font-weight: 500!important;
}

.content__task__arrow{
    min-width: 8px;
    max-width: 8px;
    height: 14px;
    align-self: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    right: -4px;
}

.content__task__arrow svg{
    fill: var(--subhead-secondary);
}

.content__task__data{
    font-style: normal;
    font-weight: 400;
    max-width: 260px;
    font-size: 13px;
    line-height: 18px;
    color: var(--text-tag-medium-gray);
}

.content__task__data.bones {
    height: 18px;
    border-radius: 5px;
}

.content__task__container{
    width: 100%;
    max-height: 188px;
    height: fit-content;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    padding-bottom: 40px;
    margin-bottom: -40px;
}

.scroll__hide{
    height: 10px;
    background-color: var(--background-island);
    position: absolute;
    z-index: 100;
    margin-bottom: 20px;
    border-radius: 20px;
}

.content_simple_wrapper{
    position: relative;
    width: calc(100vw - 16px);
    margin-left: -16px;
    margin-bottom: 8px;
    overflow: hidden;
    opacity: 1;
    transition: all .3s;
    /* border-radius: 8px; */
}

.content_simple_wrapper.deleted{
    opacity: 0;
}

.task__delete{
    height: 100%;
    background-color: var(--controlaccent-red);
    border-radius: 8px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;
}

.task__delete svg{
    margin-left: 20px;
}

.task__transition{
    transition: all .3s;
}

.task__removed{
    transition: height .3s .2s, margin-bottom .3s .4s;
    height: 0;
    margin-top: 0;
    margin-bottom: 0!important;
}

.task__delete__wrapper{
   width: calc(100vw - 30px);
   background-color: var(--controlaccent-red);
   margin-left: 8px;
   border-radius: 8px;
   flex-shrink: 0;
}

.task__delete__white{
    width: 8px!important;
}

.task__delete__deleted{
    transition: background-color .3s .2s;
    background-color: var(--background-label-gray);
}

.content__task.task__content__deleted{
    height: 0!important;
    overflow: hidden;
    padding: 0!important;
    transition: margin-left .2s, height .3s .2s, padding .3s .2s, ;
}

.content__task__stored{
    background-color: var(--background-label-green);
    border-radius: 10px;
    color: var(--text-tag-medium-gray);
    height: 21px;
    font-style: normal;
    line-height: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 2px 6px 2px;
    align-content: center;
    font-size: 11px;
    margin-right: 8px;
}

.content__task__stored:before{
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    margin-right: 4px;
    background-color: var(--controlaccent-green);
    border-radius: 50%;
}

.content__task__outdated{
    background-color: var(--background-label-red);
    border-radius: 10px;
    color: var(--controlaccent-orange);
    height: 21px;
    font-style: normal;
    line-height: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 2px 6px 2px;
    align-content: center;
    font-size: 11px;
    margin-right: 8px;
}

.content__task__outdated svg{
    margin-right: 4px;
}

/* 
.content__task__id{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: var(--title-primary);
} */