.marketplace{
    padding-bottom: 20px;
    min-height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
}

.marketplace__header{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: var(--title-primary);
    margin-bottom: 12px;
}

.marketplace__search {
    width: 100%;
    height: 50px;
    position: relative;
    margin-bottom: 12px;
    border-radius: 14px;
    background-color: var(--background-island);
}

.marketplace__search::before{
    content: '';
    position: absolute;
    left: 12px;
    top: 13px;
    width: 24px;
    z-index: 2;
    height: 24px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../images/search.svg');
}

.marketplace__input{
    width: 100%;
    height: 100%;
    padding-left: 48px;
    display: flex;
    align-items: center;
    align-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--title-primary);
    position: relative;
    z-index: 1;
    border-radius: 14px;
    background-color: var(--background-island);
    border: none;
}

.marketplace__input::placeholder{
    color: var(--text-tag-medium-gray);
}

.marketplace__filters__wrapper{
    width: 100%;
    height: 34px;
    overflow: hidden;
}

.marketplace__filters{
    width: 100%;
    overflow: scroll;
    height: 60px;
    margin-bottom: -26px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
}

.marketplace__filter{
    background-color: var(--background-substrate-default);
    color: var(--controlaccent-blue);
    border-radius: 12px;
    padding: 7px 12px;
    margin-right: 11px;
    flex-shrink: 0;
    cursor: pointer;
}

.marketplace__filter.active{
    color: var(--controlaccent-white);
    background-color: var(--background-substrate-active);
}

.marketplace__filters__wrapper{
    margin-bottom: 24px;
}

.marketplace__wrapper{
    margin-bottom: 26px;
}

.marketplace__wrapper__title{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: var(--title-primary);
}

.marketplace__wrapper__title span{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: var(--subhead-secondary);
    opacity: 0.5;
    margin-left: 8px;
    display: inline-block;
}

.marketplace__experts{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    overflow: hidden;
    align-items: flex-start;
    align-content: flex-start;
    transition: all .4s;
    position: relative;
}

.marketplace__expert{
    margin-top: 20px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    flex-shrink: 0;
    width: calc(50% - 20px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    height: 187px;
    padding-bottom: 25px;
}

.marketplace__expert:after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 175px;
    background: linear-gradient(0deg, rgba(31, 32, 35, 0.8) 0%, rgba(29, 30, 33, 0.48) 51.71%, rgba(27, 28, 31, 0) 93.3%);
    border-radius: 12px;
    bottom: 0;
    left: 0;
}

.marketplace__expert__title{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    z-index: 1;
    letter-spacing: -0.24px;
    width: 100%;
    color: var(--controlaccent-white);
    margin-bottom: 4px;
}

.marketplace__expert__describe {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
    line-height: 16px;
    color: var(--controlaccent-white);
}

.marketplace__expert__tasks{
    padding: 3px 8px;
    position: absolute;
    bottom: -12px;
    background-color: var(--background-substrate-default);
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 1;
    border-radius: 12px;
    justify-content: center;
    color: var(--controlaccent-blue);
}

.marketplace__expert__tasks.subscribe{
    bottom: 19px;
}

.marketplace__show__more{
    margin-left: auto;
    margin-right: auto;
    background-color: var(--background-island);
    color: var(--title-primary);
    padding: 6px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    width: 115px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.marketplace__show__more p{
    margin-right: 6px;
}
.marketplace__show__more span{
    transform: rotate(90deg);
    width: 7px;
    height: 7px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 2px;
}

.marketplace__show__more .rotate{
    transform: rotate(-90deg);
}

.marketplace__show__more svg{
    width: 100%;
    height: 100%;
}

.marketplace__text{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.08px;
    color: var(--text-tag-medium-gray);
    padding-left: 10px;
    padding-right: 10px;
}

.marketplace__empty{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.marketplace__img{
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../images/Detective.png');
}
