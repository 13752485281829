.main__button{
    width: calc(100% - 32px);
    transition: top .27s .07s;
    height: 50px;
    border-radius: 14px;
    background-color: var(--button-blue-default);
    color: var(--controlaccent-white);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    line-height: 22px;
    position: fixed;    
    left: 16px;
    top: calc(var(--tg-viewport-stable-height) - 66px);
    text-decoration: none;
}

.main__button.big{
    width: 100%;
    left: 0;
    right: 0;
}

.main__button.cancelTouch{
    pointer-events: none;
}

.main__button.lottie{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: auto;
    padding: 16px 51px 16px 16px;
    line-height: 20px;
    width: 100%;
    left: 0;
    letter-spacing: -0.24px;
}

.main__button__lottie{
    width: 67px;
    height: 67px;
    flex-shrink: 0;
    margin-right: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.main__button.green{
    background-color: #34C759;
}

.main__button.disabled {
    opacity: 0.7;
    background-color: var(--button-gray-disabled)!important;
    color: var(--text-tag-medium-gray)!important;
}

.main__button.yellow{
    background-color: #F1BE12;
}

.main__button.relative{
    position: relative;
    top: 0;
    margin-bottom: 20px;
    margin-top: 16px;
}

.main__button.noMargin{
    margin-bottom: 0;
}

.main__button.white{
    background-color: var(--controlaccent-white);
    color: var(--controlaccent-blue);
}

.main__button__flex{
    width: 100%;
    display: flex;
}

.main__button__second{
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin-left: 8px;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--background-island);
    border-radius: 9px;
    margin-bottom: 20px;
    margin-top: 16px;
    cursor: pointer;
}

.main__button__second:after{
    content: '';
    position: absolute;
    width: 26px;
    height: 0px;
    transform: rotate(45deg);
    border: 1px solid var(--text-tag-medium-gray);
}

.main__button__second:before{
    content: '';
    position: absolute;
    width: 26px;
    height: 0px;
    transform: rotate(-45deg);
    border: 1px solid var(--text-tag-medium-gray);
}
