.modal__container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.494);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.modal__wrapper{
    padding: 20px 22px 0;
    background-color:  var(--background-global);
    max-width: 270px;
    border-radius: 14px;
}

.modal__title{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 2px;
    color: var(--title-primary);
}

.modal__describtion {
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    color: var(--title-primary);
}

.moodal__buttons__container{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    border-top: 1px solid var(--background-substrate-disabled-gray);
    border-radius: 0 0 14px 14px;
    width: calc(100% + 44px);
    margin-left: -22px;
    margin-top: 16px;
}

.modal__button{
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    height: 45px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    color: var(--controlaccent-blue);
}

.modal__button:not(:first-child){
    border-left: 1px solid var(--background-substrate-disabled-gray);
}

.modal__button__red{
    color: #DE3A3A;
}

.modal__button__bold{
    font-weight: bold;
}