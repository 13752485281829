.calendar{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.656);
    width: 100vw;
    height: 100vh;
}

.calendar__poopup{
    width: 360px;
    background-color: var(--background-island);
    border-radius: 13px;
    padding: 28px 15px 0;
}

.calendar__header__label{
    color: var(--title-primary);
}

.calendar__header{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: var(--title-primary);
}

.calendar__header.center{
    justify-content: center;
}

.calendar__header__arrow{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 9px;
    cursor: pointer;
    height: 16px;
}

.calendar__header__arrow svg{
    fill: var(--controlaccent-blue);
    color: var(--controlaccent-blue);
}

.calendar__header__arrow.prevmonth{
    transform: rotate(180deg);
}

.calendar__body{
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    align-content: center;
    justify-content: center;
}

.calendar__cell{
    text-align: center;
    height: 34px;
    width: 34px;
    font-style: normal;
    border-radius: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color:  var(--title-primary);
}

.calendar__disabled{
    background-color: transparent!important;
    color: var(--text-tag-medium-gray)!important;
    pointer-events: none;
    cursor: default;
}
.calendar__row{
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 5px;
}
.calendar__cell.active{
    background-color: var(--controlaccent-blue);
    color: var(--controlaccent-white);
}

.calendar__time__wrapper{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}

.calendar__time__field{
    background-color: var(--background-label-gray);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 36px;
    flex: 1 1;
    margin-right: 16px;
    border-radius: 10px;
    padding-left: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.408px;    
    cursor: pointer;
    color: var(--text-tag-medium-gray);
}

.calendar__time__field svg{
    margin-right: 8px;
}

.calendar__time__reset{
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    cursor: pointer;
    color: var(--controlaccent-blue);
}

.calendar__buttons{
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 0 0 13px 13px;
    border-top: 1px solid var(--background-substrate-disabled-gray);
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-top: 22px;
}

.calendar__button{
    height: 54px;
    width: 50%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: var(--controlaccent-blue);
}

.calendar__button.bold{
    font-weight: 600;
    border-left: 1px solid var(--background-substrate-disabled-gray);
}
.calendar__button.disabled{
    color: var(--text-tag-medium-gray);
    border-left: 1px solid var(--background-substrate-disabled-gray);
    pointer-events: none;
}

.calendar__time__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.calendar__time__arrow{
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    transform: rotate(-90deg);
}

.calendar__time__arrow.decrease{
    transform: rotate(90deg);
}

.calendar__time__arrow svg{
    fill: var(--title-primary);
    color: var(--title-primary);
    width: 17px;
    height: 17px;
}

.calendar__time__value{
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    letter-spacing: -0.078px;
    color: var(--title-primary);
}
.calendar__time__value:focus{
    outline: none;
    pointer-events: none;
}

.calendar__time__divider {
    font-size: 40px;
    margin-left: 20px;
    margin-bottom: 7px;
    margin-right: 20px;
    color: var(--title-primary);
}

.calendar__todaysDate{
    color: var(--controlaccent-blue);
}

.calendar__time__wrapper_new{
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 100px;
}

.calendar__radian{
    position: relative;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
}

.calendar__radian:after{
    content: '';
    width: 100%;
    top: -30px;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    height: 70px;
    transform: rotate(-180deg);
    background: linear-gradient(0, var(--background-island) 50.26%, var(--gradient-color) 107.69%);
    filter: blur(2px);
}

.calendar__radian:before{
    content: '';
    width: 100%;
    bottom: -29px;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    transform: rotate(-180deg);
    height: 70px;
    background: linear-gradient(180deg, var(--background-island) 40.26%, var(--gradient-color) 107.69%);
    filter: blur(2px);
}

.calendar__header__input{
    width: 96px;
    height: 80px;
    text-align: center;
    color: var(--title-primary);
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
    display: flex;
    align-items: center;
    align-content: center;
    caret-color: var(--controlaccent-blue);
    background-color: var(--background-global);
    border: none;
    outline: none;
    border-radius: 6px;
    margin-bottom: 7px;
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


@media(max-width: 370px) {
    .calendar__poopup{
        width: 254px;
    }

    .calendar__cell{
        width: 21px;
        height: 21px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 10px;
    }

    .calendar__header{
        font-size: 12px;
    }

    .calendar__header__arrow{
        width: 7px;
        height: 12px;
    }

    .calendar__button{
        height: 36px;
        font-size: 12px;
    }

    .calendar__time__reset{
        font-size: 12px;
    }

    .calendar__time__field{
        font-size: 10px;
    }

    .calendar__time__value{
        font-size: 28px;
    }

    .calendar__time__divider{
        font-size: 32px;
    }

    .calendar__time__arrow svg{
        width: 14px;
        height: 14px;
    }

    .calendar__time__field svg{
        width: 12px;
        height: 12px;
    }

    .calendar__time__field{
        height: 26px;
    }
}