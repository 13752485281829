.detailmarket{
    min-height: calc(100vh - 40px);
}
.detailmarket__expert{
    width: 100%;
    height: 394px;
    border-radius: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 32px;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: center;
    margin-bottom: 8px;
}

.detailmarket__expert:after{
    content: '';
    display: block;
    width: 100%;
    height: 237px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(31, 32, 35, 0.8) 0%, rgba(29, 30, 33, 0.48) 51.71%, rgba(27, 28, 31, 0) 93.3%);
    border-radius: 14px;
}

.detailmarket__name{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    position: relative;
    z-index: 1;
    width: 100%;
    line-height: 26px;
    text-align: center;
    color: var(--controlaccent-white);
    margin-bottom: 4px;
}

.detailmarket__describe{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    z-index: 1;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--controlaccent-white);
}

.detailmarket__socials{
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.detailmarket__subscribe{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--controlaccent-white);
    padding: 15px 23px;
    background-color: var(--controlaccent-blue);
    border-radius: 14px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
}

.detailmarket__subscribe.white{
    background-color: var(--background-island);
    color: var(--title-primary);
}

.detailmarket__social{
    width: 54px;
    height: 54px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    background-color: var(--background-island);
}

.detailmarket__social path{
    fill: var(--controlaccent-blue);
}

.detailmarket__subscribe.more{
    width: 54px;
    height: 54px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    font-size: 27px;
    padding-bottom: 12px;
}


.detailmarket__container{
    position: relative;
}

.detailmarket__more{
    position: absolute;
    right: 0;
    height: 94px;
    bottom: -106px;
    background-color: var(--background-substrate-disabled-gray);
    padding: 20px 16px;
    border-radius: 16px;
    display: flex;
    transition: all .25s;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}


.detailmarket__more.opend{
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

.detail__tasks{
    margin-top: 24px;
}

.detail__task__avatar{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
}

.detail__tasks .marketplace__wrapper__title{
    margin-bottom: 8px;
}