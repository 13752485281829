.detail__wrapper{
    background-color: var(--background-island);
    border: 1px solid var(--background-island);
    border-radius: 12px;
    padding: 20px 20px 12px;
    position: relative;
}

.detail__textarea{
    border-radius: 5px;
    min-height: 100px;
    font-size: 20px;
    width: 100%;
    margin-bottom: 16px;
    color: var(--subhead-primary);
    white-space: pre-wrap;
}

.detail__wrapper.detail__textarea__error{
    border: 1px solid var(--controlaccent-red);
}

.detail__textarea:focus{
    outline: none;
}

.detail__textarea.placeholder{
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.078px;
    color: var(--text-tag-medium-gray);
}

.detail__textarea.small__text{
    font-size: 16px;
}

.detail__textarea div,
.detail__textarea span,
.detail__textarea ul,
.detail__textarea li,
.detail__textarea li::marker,
.detail__textarea p {
    color: var(--subhead-primary)!important;
    background-color: transparent!important;
    font-size: 20px!important;
}

.detail__textarea.small__text div,
.detail__textarea.small__text span,
.detail__textarea.small__text ul,
.detail__textarea.small__text li,
.detail__textarea.small__text li::marker,
.detail__textarea.small__text p {
    font-size: 16px!important;
}

.detail__chip__wrapper{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.content__filter.detail__filter{
    margin-bottom: 8px;
}

.content__filter.detail__filter.disabled {
    pointer-events: none;
    background-color: var(--background-substrate-disabled-gray);
}

.content__filter.detail__filter.disabled .content__filter__text{
    color: var(--text-tag-medium-gray);
}

.content__filter.detail__filter.disabled svg{
    color: var(--text-tag-medium-gray);
    fill: var(--text-tag-medium-gray);
}

.header__title.bones {
    width: 100%;
    height: 28px;
}

.detail__placeholder{
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 20px;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.078px;
    color: var(--subhead-secondary);
    width: calc(100% - 40px);
}