.experttask {
    width: 100%;
    padding: 2px;
    margin-bottom: 1px;
    background-color: var(--background-island);
    position: relative;
    padding: 16px;
}

.experttask:last-child{
    border-radius: 2px 2px 13px 13px;
}

.experttask__chip{
    padding: 2px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: var(--background-label-red);
    color: var(--controlaccent-red);
    margin-bottom: 6px;
    width: fit-content;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.experttask__chip svg{
    fill: var(--controlaccent-red);
    color: var(--controlaccent-red);
}

.experttask__chip path{
    stroke: var(--controlaccent-white);
}

.experttask__chip circle{
    fill: var(--controlaccent-red);
    color: var(--controlaccent-red);
}

.experttask__chip.blue{
    color: var(--controlaccent-blue);
    background-color: var(--background-label-gray);
}

.experttask__chip.blue svg{
    fill: var(--controlaccent-blue);
    color: var(--background-label-grey);
}

.experttask__chip.blue circle{
    fill: var(--controlaccent-blue);
    color: var(--background-label-grey);
}

.experttask__chip.green{
    color: var(--controlaccent-green);
    background-color: var(--background-label-green);
}

.experttask__chip.green svg{
    fill: var(--controlaccent-green);
    color: var(--background-label-green);
}

.experttask__chip.green circle{
    fill: var(--controlaccent-green);
    color: var(--background-label-green);
}

.experttask__arrow{
    position: absolute;
    width: 8px;
    height: 14px;
    right: 16px;
    top: calc(50% - 7px);
}

.experttask__name{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--title-primary);
    padding-right: 8px;
}

.experttask__icon{
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 4px;
}

.experttask__icon.noPath path{
    stroke: none;
}